import { $ } from "~/zeus";
import { typedGql } from "~/zeus/typedDocumentNode";

// @ts-ignore
const GetNotificationsQuery = typedGql("query")({
  GetNotifications: [
    {
      locale: $("locale", "LocaleInputType"),
      where: $("where", "Notification_where"),
      limit: $("limit", "Int"),
      page: $("page", "Int"),
      sort: $("sort", "String"),
    },
    {
      totalDocs: true,
      totalPages: true,
      docs: {
        id: true,
        type: true,
        message: true,
        readed: true,
        deletedByUser: true,
        meta: [{},{
         value: {
          "...on Event":{
            id: true,
            permalink: true,
          },
          "...on Comment":{
            id: true,
          }
         },
         relationTo: true,
        }],
        sender:[{},{
          id:true,
          name: true,
          organiser: {
            logo: [
              {},
              {
                url: true,
              },
            ],
          },
          member: {
            avatar: [
              {},
              {
                id: true,
                url: true,
              },
            ],
          }
        }],
        createdAt: true,
      },
    },
  ],
});

export default GetNotificationsQuery;
